import React from "react";
import PageHero from "./PageHero.tsx";
import img from "../media/boende.png";
import './Accomodation.scss';
import HotelOption from "./HotelOption.tsx";
import villaDagmarImg from '../media/villa-dagmar.jpeg';
import kristiansborgImg from '../media/hotel-christiansborg.webp';
import furusundsImg from '../media/hotel-furusund.jpeg';
import marholmenImg from '../media/hotel-marholmen.jpeg';
import allemansrättenImg from '../media/hotel-tent.jpeg';

const Accomodation = () => {

  return (
    <>
      <PageHero 
        img={img}
        text={'Boende'}
      />
      
      <section className="light-background">
        <div className="content-wrapper">
          <div className="accomodation-text-outer">
            <h3>Våra förbokade alternativ</h3>
            <p>
              OBS detta innebär att vi förbokat hela vandrarhemmen och ni bokar samt betalar via oss.
              INTE via deras egna hemsidor. Först till kvarn gäller vid bokning av dessa!
            </p>
            <p>
              Bokas via mail: 
              <a href="mailto:bokningar@sannaochtobbe.se">
                bokningar@sannaochtobbe.se
              </a>
            </p>
          </div>
        </div>
      </section>

      <section className="light-background">
        <div className="content-wrapper">
          <div className="pre-booked-grid">
            <HotelOption
              hotelName="Villa Dagmar"
              img={villaDagmarImg}
              link="http://villadagmar.se/"
              linkText="villadagmar.se"
              linkDescription="För bilder se:"
              description="Charmigt vandrarhem med 10 rum, med 2 sängplatser vardera"
              price="1200kr/rum"
            />
            <HotelOption
              hotelName="Kristiansborg"
              img={kristiansborgImg}
              description="Mysigt vandrarhem med 15 bäddar totalt. Flerbäddsrum. "
              price="500kr/bädd"
            />
          </div>
        </div>
      </section>

      <section className="light-background">
        <div className="content-wrapper">
          <div className="accomodation-text-outer">
            <h3>Andra alternativ</h3>
            <p>
              OBS dessa alternativ får ni själva boka.
            </p>
          </div>
        </div>
      </section>

      <section className="light-background">
        <div className="content-wrapper">
          <div className="self-book-grid">
            <HotelOption
              hotelName="Furusunds världshus"
              img={furusundsImg}
              link="https://hotellfurusund.se/"
              linkText="hotellfurusund.se"
              linkDescription="Boka har:"
            />
            <HotelOption
              hotelName="Marholmen"
              img={marholmenImg}
              description="Ligger 8 km bort."
              linkDescription="Boka har:"
              linkText="marholmen.se"
              link="https://marholmen.se/"
            />
            <HotelOption
              hotelName="Allemansrätten"
              img={allemansrättenImg}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default Accomodation;